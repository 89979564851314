.publications-content {
  margin: 0 auto 0 auto;
  max-width: 1000px;
}

.pub-box {
    padding: 20px;
}

.pub-list {
    margin-top: 1em;
    font-size: 17px;
    list-style: none;
}

.pub-list li {
    margin-top: 1em;
}

.pub-list ul {
    list-style: none;
}

.pub-list-heading {
    margin-top: 1em;
}

.pub-authors {
    font-size: 14px;
    font-style: italic;
    line-height: 1em;
}

.smallfont {
    margin-top: 2em;
    font-size: 12px;
}