.speaking-content {
  margin: 0 auto 0 auto;
  max-width: 1000px;
}

.speaking-box {
    padding: 20px;
}

.speaking-list {
    margin-top: 1em;
    font-size: 18px;
    list-style: none;
}

.speaking-list li {
    margin-top: 1em;
}

.speaking-list ul {
    list-style: none;
}

.speaking-list-heading {
    margin-top: 1em;
}

.conf-list {
    line-height: 2.0em;
}

.academ-preface {
    margin-top: 1em;
}

.logo-box {
    text-align: center;
    margin-top: 1em;
}

.logo-image {
    max-width: 160px;
    margin: 1em;
}