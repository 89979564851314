.contact-content {
  margin: 0 auto 0 auto;
  max-width: 1000px;
}

.contact-dialog {
  margin: 1em auto 1em auto;
  max-width: 1000px;
  display: none;
}

.form-group {
  margin: 1em auto 1em auto;
}

.form-control {
  margin: 1em auto 1em auto;
  width: 50%;
}

.form-message {
  margin: 1em auto 1em auto;
  width: 100%;
}

.contact-box {
    padding: 20px;
}

.contact-list {
    margin-top: 1em;
    font-size: 18px;
    list-style: none;
}

.contact-blurb {
    margin-top: 1em;
    line-height: 1.5;
}

.contact-email {
    margin-top: 1em;
    line-height: 1.5;
}

.contact-twitter {
    margin-top: 1em;
}

.contact-image {
    margin-top: 2em;
    max-width: 100%;
}