.bio {
  margin: 0 auto;
  max-width: 1200px;
}

.bio-img {
  display: block;
  min-width: 240px;
  max-width: 240px;
  max-height: 240px;
  width: 240px;
  height: 240px;
  padding: 0 20px 5px 0;
}

.bio-photo {
  width: 100%;
  height: 100%;
}

.bio-description {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 630px;
  font-family: PT Serif,Georgia,Times,serif;
  font-size: 1rem;
  line-height: 1.4;
}

.bio-body {
  display: flex;
  padding: 20px;
}

.bio-heading {
  display: flex;
}

@media (max-width: 600px) {
  .bio-description {
    display: inline-block;
  }
  .bio-body {
    display: inline-block;
  }
}
