.footer {
    color: grey;
    font-size: 11px;
    letter-spacing: .5px;
    line-height: 20px;
    text-align: center;
    padding-bottom: 1em;
    padding-top: 1em;
    margin-bottom: 2em;
}

.footer-logo {
    display: flex;
    justify-content: space-around;
    padding-top: 2em;
    padding-bottom: 2em;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    margin-top: 2em;
    margin-bottom: 2em;
}

.email {
    font-size: 18px;
    padding-top: 1em;
}

.email-link {
    text-decoration: none;
    color: white;
}

.yaydocker {
    margin-bottom: 2em;
}