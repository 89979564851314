h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  font-family: Zilla Slab, Lora, Georgia, Times, serif;
  margin-top: .6em;
  margin-bottom: .6em;
}

h3 {
  font-size: 32px;
  letter-spacing: .5px;
}

.contact-banner {
    color: white;
    background-color: #666;
    text-align: center;
    margin: 1em;
    padding-top: 1em;
    padding-bottom: 2.75em;
}