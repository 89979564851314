.career-content {
  margin: 0 auto 0 auto;
  max-width: 1000px;
}

.career-box {
    padding: 20px;
}

.career-list {
    margin-top: 1em;
    font-size: 18px;
    list-style: none;
}

.career-list li {
    margin-top: 1em;
}

.career-list ul {
    list-style: none;
}

.career-list-heading {
    margin-top: 1em;
}

.career-blurb {
    line-height: 1.7;
}

.career-authors {
    font-size: 14px;
    font-style: italic;
    line-height: 1em;
}

.vspace {
    margin-top: 1.5em;
}